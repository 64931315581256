import React from "react";
import styles from "./ProductCard.module.css";
import {currencyFormatter} from "../../../utils/currencyFormatter/currencyFormatter";
import reviewImage from "../../../img/dashboard/posts/status_review.png";
import approveImage from "../../../img/dashboard/posts/status_live.png";
import rejectImage from "../../../img/dashboard/posts/status_rejected.png";
import rentalImage from "../../../img/dashboard/posts/status_rental.png";
import moment from "moment";
import avatarPlaceholder from "../../../img/home/home/avatar_placeholder.png";
import requestedIcon from "../../../img/components/Filters/requested.png";
import approvedIcon from "../../../img/components/Filters/approved.png";
import declinedByCustomerIcon from "../../../img/components/Filters/declinedByCustomer.png";
import rentProcessingIcon from "../../../img/components/Filters/rentProcessing.png";
import completedIcon from "../../../img/components/Filters/completed.png";
import {useTranslation} from "next-i18next";
import {motion} from "framer-motion";
import Link from "next/link";
import Like from "../Like/Like";
import {placeholderImage} from "../../../constants";
import Rating from "../Rating/Rating";
import {useAppSelector} from "../../../redux/store";
import cutStringWithDots from "../../../utils/cutStringWithDots/cutStringWithDots";

interface IProps {
	key?: string;
	data: any;
	type: "homePosts" | "homeNearby" | "myPosts" | "borrowed" | "rented" | "wishlist";
	filterData?: any;
	containerStyle?: React.CSSProperties;
	liked?: boolean;
	handleLike?: (state:boolean) => void;
	withoutDistance?: boolean;
}

export default function ProductCard(props:IProps) {
	const { t } = useTranslation();
	const user = useAppSelector((state) => state.user.user);
	const reduxFavourites = useAppSelector((state) => state.favourites.favourites);

	const isLiked = () => {
		if (reduxFavourites?.includes(props.data._id)) {
			return true
		} else {
			return false
		}
	}

	const getProductStatus = () => {
		if (!props.data.approved) {
			if (props.data.rejected || props.data.rejectReason) {
				return <img
					src={rejectImage.src}
					alt="reject_shary"
				/>
			} else {
				return <img
					src={reviewImage.src}
					alt="review_shary"
				/>
			}
		} else {
			if (!props.data.active) {
				return <img
					src={rejectImage.src}
					alt="reject_shary"
				/>
			} else if (props.data.rented && props.data.rented.length > 0) {
				return <img
					src={rentalImage.src}
					alt="rented_shary"
				/>
			} else {
				return <img
					src={approveImage.src}
					alt="approved_shary"
				/>
			}
		}
	}

	const getOrderStatus = () => {

		switch (props.data.status) {
			case "requested":
				return <img
					src={requestedIcon.src}
					alt="request_shary"
				/>
			case "approved":
				if (
					new Date() > moment(props.data.dates[0], "DD.MM.YYYY").toDate()
				) {
					return <img
						src={rentProcessingIcon.src}
						alt="rentProcessing_shary"
					/>
				} else {
					return <img
						src={approvedIcon.src}
						alt="approve_shary"
					/>
				}
			case "rejected":
				return <img
					src={rejectImage.src}
					alt="reject_shary"
				/>
			case "declinedByCustomer":
				return <img
					src={declinedByCustomerIcon.src}
					alt="declinedByCustomer_shary"
				/>
			case "rentProcessing":
				return <img
					src={rentProcessingIcon.src}
					alt="rentProcessing_shary"
				/>
			case "completed":
				return <img
					src={completedIcon.src}
					alt="completed_shary"
				/>
			case "dispute":
				return <img
					src={rejectImage.src}
					alt="reject_shary"
				/>
		}
	}

	const getBorrowedDate = () => {
		if (props.data?.hourlyRent?.activated) {
			return `${moment(props.data.hourlyRent.startTime).format("DD.MM.YYYY")}, ${moment(props.data.hourlyRent.startTime).format("HH:mm")} - ${moment(props.data.hourlyRent.endTime).format("HH:mm")}`
		} else {
			return `${props.data.dates[0]} - ${props.data.dates[props.data.dates.length - 1]}`
		}
	}

	const returnLink = () => {
		switch (props.type) {
			case "borrowed":
				return `/dashboard/borrowed/${props.data._id}`
			case "rented":
				return `/dashboard/rented/${props.data._id}`
			case "homeNearby":
				return `/post/${props.data.path}`
			case "homePosts":
				return `/post/${props.data.path}`
			case "wishlist":
				return `/post/${props.data.path}`
			case "myPosts":
				return `/dashboard/posts/${props.data.path}`
		}
	}

	const getUserName = () => {
		const name = `${props.data.owner ? props.data.owner[0].firstName : props.data.renter[0].firstName}`
		return cutStringWithDots({string: name, limit: 10});
	}

	return(
		<div
			className={styles.product_card_container}
			style={props.containerStyle}
			key={props.key}
			data-testid="cy_product_card"
		>
			<div
				className={styles.product_card_holder}
				style={props.type === "myPosts"
					? {height: "350px"}
					: props.type === "borrowed"
						? {height: "340px"}
						: {}
				}
			>
				{props.type === "homePosts" || props.type === "wishlist"
					? <Like
						likedProp={isLiked()}
						userId={user?._id}
						productName={props.data.path}
						postId={props.data._id}
						handleLike={(state: boolean) => {
							if (props.handleLike) {
								props.handleLike(state)
							}
						}}
					/>
					: null
				}
				<Link
					href={returnLink()}
					rel="follow"
				>
					<div
						className={styles.product_card}
						style={props.type === "myPosts"
							? {height: "350px"}
							: props.type === "borrowed"
								? {height: "340px"}
								: {}
						}
					>
						<div
							style={{
								backgroundImage: `url("${props.data?.imagesUrls ? props.data.imagesUrls[0] : placeholderImage}")`
							}}
							className={styles.product_card_img}
						/>

						<div className={styles.product_card_content}>
							<div className="space-between">
								<h6 className={styles.product_card_content_title}>
									{cutStringWithDots({string: props.data.title, limit: 24})}
								</h6>
								{props.type === "myPosts" || props.type === "borrowed"
									? <div className={styles.product_card_content_status}>
										{props.type === "myPosts"
											? getProductStatus()
											: getOrderStatus()
										}
									</div>
									: <div>
										<p className={styles.product_card_content_price}>
											{currencyFormatter({
												currency: props.data.pricing.currency,
												amount: props.type === "rented"
													? props.data.pricePerDay
													: props.data.pricing.daily["1"]
 											})}
										</p>
										<p className={styles.product_card_content_type}>
											{t("components.product_card.daily")}
										</p>
									</div>
								}
							</div>

							{props.type === "myPosts"
								? <div className={styles.product_card_content_info_row}>
									<p>{t("dashboard.posts.posts.card.rentals", {
										number: props.data.completedRentals.length
									})}</p>
								</div>
								: null
							}

							{props.type === "myPosts"
								? <div className={styles.product_card_content_info_row}>
									<p>{t("dashboard.posts.posts.card.earned", {
										amount: currencyFormatter({
											amount: props.data.rentalsAmount,
											currency: "eur"
										})
									})}</p>
								</div>
								: null
							}

							{props.type === "myPosts"
								? <div className={styles.product_card_content_info_row}>
									<p>{t("dashboard.posts.posts.card.created", {
										date: moment(props.data.createdAt).format("DD.MM.YYYY HH:mm")
									})}</p>
								</div>
								: null
							}

							{props.type === "borrowed"
								? <div className={styles.product_card_content_info_row}>
									<p>{t("dashboard.borrowed.card.time_range", {
										date: getBorrowedDate()
									})}</p>
								</div>
								: null
							}

							{props.type === "borrowed"
								? <div className={styles.product_card_content_info_row} style={{marginBottom: "-10px"}}>
									<p>{t("dashboard.borrowed.card.total_amount", {
										amount: currencyFormatter({
											amount: props.data.amountForLender,
											currency: "eur"
										})
									})}</p>
								</div>
								: null
							}

							<div className="space-between" style={{marginTop: "20px"}}>

								<div className={styles.product_card_content_footer}>
									<motion.img
										src={props.data.owner
											? props.data.owner[0]?.imageUrl?.length > 0
												? props.data.owner[0].imageUrl
												: avatarPlaceholder.src
											: props.data.renter[0]?.imageUrl?.length > 0
												? props.data.renter[0].imageUrl
												: avatarPlaceholder.src
										}
										alt={`${props.data.owner
											? cutStringWithDots({string: props.data.owner[0].firstName, limit: 20})
											: props.data.renter[0].firstName}`}
									/>
									<p>{getUserName()}</p>
									{props.type !== "borrowed" && props.type !== "rented"
										? <Rating rating={props.data?.ratings?.rating} />
										: null
									}

								</div>

								{!props.withoutDistance && props.type !== "myPosts" && props.type !== "borrowed" && props.type !== "rented" && props.type !== "wishlist"
									? <div className={styles.product_card_content_footer_distance}>
										{`< ${props.data.distance < 1000 ? 1 : Math.floor(props.data.distance / 1000)} km`}
									</div>
									: null
								}

							</div>

						</div>

					</div>
				</Link>
			</div>
		</div>
	)
}
