import React, {useEffect, useState} from "react";
import styles from "./Like.module.css";
import heartFilled from "../../../img/home/posts/heart_filled.png";
import heartOutlined from "../../../img/home/posts/heart_outlined.png";
import Lottie from "react-lottie";
import likesAnimation from "../../../img/animation/likesAnimation.json";
import {motion} from "framer-motion";
import FavouritesService from "../../../services/Favourites/favourites.service";
import {useAppSelector} from "../../../redux/store";
const TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID!;

interface IProps {
	likedProp: boolean;
	userId?: string;
	productName: string;
	postId: string;
	reduxFavourites?: any;
	handleLike?: (state: boolean) => void;
}

export default function Like(props:IProps) {
	const [liked, setLiked] = useState(false)
	const [showAnimation, setShowAnimation] = useState(false)
	const [showUnlikeAnimation, setShowUnlikeAnimation] = useState(false)
	const user = useAppSelector((state) => state.user.user);

	useEffect(() => {
		setLiked(props.likedProp)
	}, [props.likedProp])

	const handleLike = () => {
		if (!liked) {
			gtag("event", "add_to_wishlist", {
				method: "Like",
				send_to: TRACKING_ID
			});
		}
		setShowAnimation(true)
		// Like
		setLiked(!liked)
		setTimeout(() => {
			setShowAnimation(false)
		}, 2700)
		if (props.userId || user) {
			props.handleLike && props.handleLike(!liked);
			new FavouritesService().addToFavourites({postId: props.postId});
		}
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: likesAnimation,
	};

	return(
		<div>
			{showAnimation
				? <div className={styles.product_card_heart_animation}>
					<Lottie
						isClickToPauseDisabled={true}
						options={defaultOptions}
					/>
				</div>
				: null
			}
			{showUnlikeAnimation
				? <div className={styles.product_card_heart_animation}>
					<Lottie
						isClickToPauseDisabled={true}
						options={defaultOptions}
					/>
				</div>
				: null
			}
			<motion.img
				className={styles.product_card_heart}
				src={liked
					? heartFilled.src
					: heartOutlined.src
				}
				alt={liked
					? `wishlist_${props.productName}`
					: `not_liked_${props.productName}`
				}
				onClick={(e) => {
					e.stopPropagation();
					handleLike()
				}}
			/>
		</div>
	)
}
