import styles from "./Rating.module.css";
import { motion } from "framer-motion";
import starIcon from "../../../img/components/ProductCard/star.png";
import fireIcon from "../../../img/components/shared/rating/flame.png";
import {useTranslation} from "next-i18next";

interface IProps {
	rating: number;
	style?: React.CSSProperties;
}

export default function Rating(props: IProps) {
	const { t } = useTranslation();

	return(
		<div className="row">

			{props.rating > 0
				? <div
					className={styles.product_card_content_footer_rating}
					style={props.style}
				>
					<p>{props.rating}</p>
					<motion.img
						src={starIcon.src}
						alt={"star"}
					/>
				</div>
				: <>
					<div
						className={styles.product_card_content_footer_rating}
						style={props.style}
					>
						<p>
							{t("components.shared.rating.new")}
						</p>
						<motion.img
							src={fireIcon.src}
							alt={"fire"}
						/>
					</div>
				</>
			}

		</div>
	);
}
