import {requestHandler} from "../../utils/requestHandler";
import {url} from "../../apiVariables/urls";

export type AddToFavouritesType = {
	postId: string;
}

export default class FavouritesService {
	constructor() {}

	async getAllFavouritesIds(): Promise<string[]> {
		return requestHandler({
			path: url.dashboard.wishlist.getAll,
			method: "GET",
			protected: true,
			customErrorHandler: true
		})
			.then((res:any) => {
				if (res.status && res.status !== 401 && res.data && res.data.favourites) {
					return res.data.favourites;
				}
			})
			.catch((err:any) => {
				console.error(err)
			})
	}

	addToFavourites(data: AddToFavouritesType) {
		requestHandler({
			path: url.home.favourites.favourite,
			method: "POST",
			data: {postId: data.postId},
			protected: true,
			customErrorHandler: true
		}).then(async () => {
			return true
		}).catch((err: any) => {
			console.error(err)
			return true
		})
	}

}
